export default {
  name: 'Stories',
  title: 'Stories: Read the stories to know more about the community',
  data() {
    return {
      stories: [
        {
          slug: 'people-are-paying-for-e-travel',
          title: 'People are paying for e-travel',
          date: 'April 27',
          year: '2020',
          img: '31.jpg',
          content: 'Lately it feels like joy has been sucked out of our lives. The world got cancelled one by one. Major world events, national events, local events, movie releases, concerts, festivals, it...'
        },
        {
          slug: 'que-sera-sera',
          title: 'Que Sera Sera',
          date: 'April 10',
          year: '2020',
          img: '30.jpg',
          content: 'Sigh! The World is going through a pandemic. It took me a while to accept it, but it really is. Localites is a global community, and all these while we constantly celebrated our differences...'
        },
        {
          slug: 'how-travel-is-flowing-by-virtue',
          title: 'How travel is flowing by virtue?',
          date: 'Mar 16',
          year: '2020',
          img: '29.jpg',
          content: 'Flow! What comes to your mind when you hear this word? Dance, River, may be life. Flow though is now a legit concept in positive psychology born out of rigorous qualitative research and it...'
        },
        {
          slug: 'celebrating-130-countries',
          title: 'Celebrating <b>130</b> countries',
          date: 'Mar 6',
          year: '2020',
          img: '28.png',
          content: 'We are thrilled! Now people from 130 countries are part of the community and exploring and traveling in different corners of the world. So we invite you to be a part of the community today...'
        },
        {
          slug: 'why-do-millennials-need-to-travel-the-real-world',
          title: 'Why do millennials need to travel the real world?',
          date: 'Feb 25',
          year: '2020',
          img: '27.jpg',
          content: 'There is no way I can say this without keeping my biases aside, but Millennials are the best. This generation is the most miserable and magical set of humans that live across the planet...'
        },
        {
          slug: 'what-is-localites',
          title: 'What is <b>localites</b>?',
          date: 'Aug 31',
          year: '2019',
          img: '1.png',
          content: 'For decades the way of traveling has always been the same. People would pack their bags, board a plane or train, reach the destination, rest for a while in a hotel or on a friend’s couch...'
        },
        {
          slug: 'stories-of-change',
          title: 'Stories of <b>Change</b>',
          date: 'Feb 18',
          year: '2020',
          img: '26.jpg',
          content: 'Sometimes I sit and wonder, what is my favourite thing about this world? What makes this planet so alive and special for me? A part of me knows it’s the animals, the fishes, the trees, the...'
        },
        {
          slug: 'coronavirus-outbreak-reality-and-precautions',
          title: '<b>Coronavirus</b>: Outbreak, Reality and Precautions',
          date: 'Feb 10',
          year: '2020',
          img: '25.jpg',
          content: 'When all of us across different countries were celebrating the new year in all our different local ways, China was at the tipping point of what now is a global health emergency...'
        },
        {
          slug: 'must-experience-festivals-of-spain',
          title: 'Must experience festivals of <b>Spain</b>',
          date: 'Jan 21',
          year: '2020',
          img: '23.png',
          content: 'Spain is life. Spain is beauty. Spain is colours. Spain is passion. And it is always celebrating. The Spanish are full of energy, always ready to play, to dance and to celebrate their...'
        },
        {
          slug: 'the-meaning-of-silence',
          title: 'The meaning of <b>Silence</b>',
          date: 'Jan 7',
          year: '2020',
          img: '21.png',
          content: 'Silence. It is awkward for some, peaceful for others. Silence, and its meaning changes as you move from country to country, or culture to culture. Experiencing culture is an inevitable...'
        }
      ]
    }
  }
}
